import { Box } from '@chakra-ui/react';
import { Image } from '@/components/Image';
import { TopViewContents } from '@/features/brand/types/brand';


export type SpBrandTopViewProps = {
  topViewContents: TopViewContents;
}

export const SpBrandTopView = ({
  topViewContents: {
    spTopImageUrl,
    topImageAlt,
    brandLogoImage,
    brandName
  }
}: SpBrandTopViewProps) => {
  return (
    <>
      <Box
        textAlign={'center'}
      >
        <Image
          src={spTopImageUrl}
          alt={topImageAlt}
          width={600}
          height={600}
        />
      </Box>
      <Box
        mx={4}
        textAlign={'center'}
      >
        <Box
          position={'relative'}
          height={'65px'}
          mt={6}
          as={'h1'}
        >
          <Image
            src={brandLogoImage}
            alt={brandName}
            layout={'fill'}
            objectFit={'contain'}
          />
        </Box>
      </Box>
    </>
  );
};
