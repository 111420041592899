import {
  Box,
  BoxProps,
  Text,
} from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { StaticImageData } from 'next/image';
import { LinkButton } from '../Link/LinkButton/LinkButton';
import { Image } from '@/components/Image';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import {
  ReturnPageInfo
} from '@/config/pages';

interface AvatarDescriptionCardProps extends BoxProps {
  imageProps: {
    imageUrl?: string | null;
    imageAltText: string;
    imageSize?: { base: number; sm: number; md: number; lg?: number; xl?: number };
    noImageData?: StaticImageData;
  }
  headingProps: {
    heading: string;
    headingTextStyle?: string;
    headingTextColor?: string;
    textDecoration?: boolean;
  }
  subHeading?: string;
  descriptionProps: {
    description: string;
    descriptionColor?: string;
    descriptionHeight?: string;
    isShortText?: boolean;
  }
  pageInfo?: ReturnPageInfo;
}

export const AvatarDescriptionCard = ({
  imageProps,
  headingProps,
  subHeading,
  descriptionProps,
  pageInfo,
  ...rest
}: AvatarDescriptionCardProps) => {
  const {
    imageUrl,
    imageAltText,
    imageSize = { base: 250, sm: 300, md: 400 },
    noImageData
  } = imageProps;
  const {
    heading,
    headingTextStyle = 'h1',
    headingTextColor = 'brand.500',
    textDecoration = false
  } = headingProps;
  const {
    description,
    descriptionColor,
    descriptionHeight = '140px',
    isShortText = false
  } = descriptionProps;
  return (
    <Box
      {...rest}
    >
      <Box
        mx={'auto'}
        width={imageSize}
        height={imageSize}
        position={'relative'}
        overflow={'hidden'}
      >
        <Image
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
            justifyContent: 'center',
          }}
          src={imageUrl || noImageData}
          alt={imageAltText}
          layout={'fill'}
          objectFit={'cover'}
          noImageSrc={noImageData}
        />
      </Box>
      <Box>
        <Text
          color={headingTextColor}
          fontWeight={'bold'}
          textAlign={'center'}
          textStyle={headingTextStyle}
          whiteSpace={'pre-line'}
          textDecoration={textDecoration ? 'underline' : 'none'}
          pt={2}
          as={'h3'}
        >
          {heading}
        </Text>
        {subHeading &&
          <Text
            color={headingTextColor}
            fontWeight={'bold'}
            textAlign={'center'}
            textStyle={'h6'}
            pt={1}
            pb={2}
          >
            {subHeading}
          </Text>
        }
      </Box>
      <Box
        color={descriptionColor}
        textAlign={'justify'}
        whiteSpace={'pre-line'}
        letterSpacing={{ base: '0.02em', lg: '0.03em' }}
        lineHeight={{ base: '1.2rem', lg: '1.35rem' }}
        height={descriptionHeight}
        p={3}
      >
        <Text
          css={isShortText && {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
          }}
        >
          {description}
        </Text>
      </Box>
      {pageInfo &&
        <LinkButton
          linkUrl={pageInfo}
          color={headingTextColor}
          text={'もっと見る'}
          w={'80%'}
          margin={'0 auto'}
          size={'lg'}
          textAlign={'center'}
        />
      }
    </Box>
  );
};

